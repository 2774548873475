import _ from 'lodash';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import formatMoney from '../../../../../../utils/currency/formatMoney';

import GoodRow from './components/GoodRow';

const GoodTable = ({ goods, onDeleteGood }) => {
  const makeGoodDeleteHandler = (good) => () => onDeleteGood(good);

  return (
    <Box>
      <Paper>
        <Toolbar sx={{ display: 'flex', '& > *:not(:last-child)': { mr: 1 } }}>
          <Typography>
            Prekių kiekis:{' '}
            <Box component="span" fontWeight="medium">
              {goods.length}
            </Box>
          </Typography>
          <Typography>
            Bendra kaina:{' '}
            <Box component="span" fontWeight="medium">
              {formatMoney(_.sumBy(goods, 'actualPrice'))}
            </Box>
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right">Kaina</TableCell>
                <TableCell align="right">Pilna kaina</TableCell>
                <TableCell align="right">Kategorija</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {goods.map((good, index) => (
                <GoodRow
                  key={index}
                  data={good}
                  onDelete={makeGoodDeleteHandler(good)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

GoodTable.propTypes = {
  goods: PropTypes.arrayOf(
    PropTypes.shape({
      actualPrice: PropTypes.number.isRequired,
    })
  ).isRequired,
  onDeleteGood: PropTypes.func.isRequired,
};

export default GoodTable;
