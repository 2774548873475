import { DateTime } from 'luxon';

import DEFAULT_TIMEZONE from '../../../../../../../../../utils/date/DEFAULT_TIMEZONE';

const makeSoldDailyReport = (soldGoods) =>
  soldGoods.reduce(
    (dailyReport, soldGood) => {
      const day = DateTime.fromISO(soldGood.created_at).setZone(
        DEFAULT_TIMEZONE
      ).weekday;

      dailyReport.total.count++;
      dailyReport.total.totalPrice += soldGood.price;
      dailyReport.total.totalActualPrice += soldGood.actualPrice;

      if (dailyReport.daily[day]) {
        dailyReport.daily[day].count++;
        dailyReport.daily[day].totalPrice += soldGood.price;
        dailyReport.daily[day].totalActualPrice += soldGood.actualPrice;
      } else {
        dailyReport.daily[day] = {
          count: 1,
          totalPrice: soldGood.price,
          totalActualPrice: soldGood.actualPrice,
        };
      }

      return dailyReport;
    },
    { daily: {}, total: { count: 0, totalPrice: 0, totalActualPrice: 0 } }
  );

export default makeSoldDailyReport;
