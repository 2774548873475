import { DateTime } from "luxon";

import DEFAULT_TIMEZONE from "../../../../../../../../../utils/date/DEFAULT_TIMEZONE";

const makeSoldGoodsCategoryCountsMapByDay = (soldGoods) =>
	soldGoods.reduce((categoryCountsMapByDay, soldGood) => {
		const day = DateTime.fromISO(soldGood.created_at).setZone(
			DEFAULT_TIMEZONE
		).weekday;

		if (categoryCountsMapByDay[day]) {
			if (categoryCountsMapByDay[day][`${soldGood.category}`]) {
				categoryCountsMapByDay[day][`${soldGood.category}`].count += 1;
				categoryCountsMapByDay[day][`${soldGood.category}`].price +=
					soldGood.price;
				categoryCountsMapByDay[day][
					`${soldGood.category}`
				].actualPrice += soldGood.actualPrice;
			} else {
				categoryCountsMapByDay[day][`${soldGood.category}`] = {
					count: 1,
					price: soldGood.price,
					actualPrice: soldGood.actualPrice,
				};
			}
		} else {
			categoryCountsMapByDay[day] = {
				[`${soldGood.category}`]: {
					count: 1,
					price: soldGood.price,
					actualPrice: soldGood.actualPrice,
				},
			};
		}

		return categoryCountsMapByDay;
	}, {});

export default makeSoldGoodsCategoryCountsMapByDay;
