import useSWR from 'swr';

import ENDPOINTS from '../ENDPOINTS';

import fetcherWithAuth from './utils/fetcherWithAuth';

const useUser = (id, token) =>
  useSWR(
    id && token ? [`${ENDPOINTS.users}/${id}`, token] : null,
    fetcherWithAuth
  );

export default useUser;
