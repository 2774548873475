import { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import formatMoney from '../../../../../../../../utils/currency/formatMoney';

import ConfirmationDialog from '../../../../../../../../components/ConfirmationDialog';

const GoodRow = ({ data, onDelete }) => {
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);

  const handleDeleteClick = () => {
    setDeleteConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    setDeleteConfirmDialogOpen(false);
    onDelete();
  };

  const handleDeleteConfirmDialogClose = () => {
    setDeleteConfirmDialogOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell align="right">{formatMoney(data.actualPrice)}</TableCell>
        <TableCell align="right">{formatMoney(data.price)}</TableCell>
        <TableCell align="right">{data.category ?? 'Nėra'}</TableCell>
        <TableCell align="right">
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        title={`Ar tikrai norite ištrinti prekę (pilna kaina - ${formatMoney(
          data.price
        )})?`}
        confirmButtonText="Ištrinti"
        open={deleteConfirmDialogOpen}
        onClose={handleDeleteConfirmDialogClose}
        onCancel={handleDeleteConfirmDialogClose}
        onConfirm={handleDeleteConfirm}
      />
    </>
  );
};

GoodRow.propTypes = {
  data: PropTypes.shape({
    price: PropTypes.number.isRequired,
    actualPrice: PropTypes.number.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default GoodRow;
