import { DateTime } from 'luxon';
import { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import DEFAULT_TIMEZONE from '../../../../../../../../../../../../../../utils/date/DEFAULT_TIMEZONE';
import formatMoney from '../../../../../../../../../../../../../../utils/currency/formatMoney';

import ConfirmationDialog from '../../../../../../../../../../../../../../components/ConfirmationDialog';

const GoodRow = ({ data, onDelete }) => {
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);

  const [deleting, setDeleting] = useState(false);

  const handleDeleteClick = () => {
    setDeleteConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setDeleteConfirmDialogOpen(false);

    setDeleting(true);
    await onDelete();
    setDeleting(false);
  };

  const handleDeleteConfirmDialogClose = () => {
    setDeleteConfirmDialogOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell align="right">{formatMoney(data.price)}</TableCell>
        <TableCell>
          {DateTime.fromISO(data.created_at)
            .setZone(DEFAULT_TIMEZONE)
            .toLocaleString(DateTime.DATETIME_SHORT)}
        </TableCell> 
        <TableCell>
          {data.category ?? 'Nėra'}
        </TableCell>
        <TableCell align="right">
          {deleting ? (
            <CircularProgress />
          ) : (
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        title={`Ar tikrai norite ištrinti prekę (kaina - ${formatMoney(
          data.price
        )})?`}
        confirmButtonText="Ištrinti"
        open={deleteConfirmDialogOpen}
        onClose={handleDeleteConfirmDialogClose}
        onCancel={handleDeleteConfirmDialogClose}
        onConfirm={handleDeleteConfirm}
      />
    </>
  );
};

GoodRow.propTypes = {
  data: PropTypes.shape({
    price: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default GoodRow;
