import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import getNextWeekday from '../../../../../../utils/date/getNextWeekday';
import USER_ROLES from '../../../../../../utils/api/USER_ROLES';
import ENDPOINTS from '../../../../../../utils/api/ENDPOINTS';
import axiosInstance from '../../../../../../utils/api/axiosInstance';
import useUser from '../../../../../../utils/api/data-hooks/useUser';
import useStore from '../../../../../../utils/api/data-hooks/useStore';
import useBatches from '../../../../../../utils/api/data-hooks/useBatches';

import { SelectedStoreContext } from '../../../../../../components/SelectedStoreProvider';
import PageTitle from '../../../../../../components/PageTitle';
import ErrorMessage from '../../../../../../components/ErrorMessage';
import CenteredCircularProgress from '../../../../../../components/CenteredCircularProgress';

import Batches from './components/Batches';
import BatchDialog from './components/BatchDialog';

const BatchList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [authState, tokenValid] = useAuthContext();

  const [storeId] = useContext(SelectedStoreContext);

  const userData = useUser(tokenValid && authState.user?.id, authState.token);

  const storeData = useStore(storeId, authState.token);

  const batchesData = useBatches(storeId, authState.token);

  const [batchDialogOpen, setBatchDialogOpen] = useState(false);

  const handleAddClick = () => {
    setBatchDialogOpen(true);
  };

  const handleBatchDialogClose = () => {
    setBatchDialogOpen(false);
  };

  const handleBatchUpdate = async (id, data) => {
    try {
      await axiosInstance.put(
        `${ENDPOINTS.batches}/${id}`,
        {
          ...data,
          store: storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${authState.token}`,
          },
        }
      );
      batchesData.mutate();
      enqueueSnackbar('Partija paredaguota sėkmingai', { variant: 'success' });
      return true;
    } catch (error) {
      enqueueSnackbar('Nepavyko paredaguoti partijos. Pabandykite dar kartą.', {
        variant: 'error',
      });
      return false;
    }
  };

  const handleBatchCreate = async (data) => {
    try {
      await axiosInstance.post(
        ENDPOINTS.batches,
        {
          ...data,
          store: storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${authState.token}`,
          },
        }
      );
      batchesData.mutate();
      enqueueSnackbar('Partija pridėta sėkmingai', { variant: 'success' });
      setBatchDialogOpen(false);
    } catch (error) {
      enqueueSnackbar('Nepavyko pridėti partijos. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  const handleBatchDelete = async (id) => {
    try {
      await axiosInstance.delete(`${ENDPOINTS.batches}/${id}`, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      batchesData.mutate();
      enqueueSnackbar('Partija ištrinta sėkmingai', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko ištrinti partijos. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  if (userData.error) {
    return (
      <ErrorMessage>
        Nepavyko gauti prisijungusio naudotojo informacijos. Pabandykite
        perkrauti puslapį.
      </ErrorMessage>
    );
  }

  if (storeData.error) {
    return (
      <ErrorMessage>
        Nepavyko gauti parduotuvės informacijos. Pabandykite perkrauti puslapį.
      </ErrorMessage>
    );
  }

  if (batchesData.error) {
    return (
      <ErrorMessage>
        Nepavyko gauti partijų sąrašo. Pabandykite perkrauti puslapį.
      </ErrorMessage>
    );
  }

  if (!userData.data || !storeData.data || !batchesData.data) {
    return <CenteredCircularProgress />;
  }

  const storeFirstDay = storeData.data.dayConfigs.find(({ first }) => first);

  return (
		<Box>
			<PageTitle>Partijos</PageTitle>
			{userData.data.role.type !== USER_ROLES.cashiers && (
				<Box
					sx={{ mb: 1, display: "flex", justifyContent: "flex-end" }}
				>
					<Button variant="contained" onClick={handleAddClick}>
						Pradėti naują partiją
					</Button>
				</Box>
			)}
			{batchesData.data.length > 0 ? (
				<Batches
					data={batchesData.data}
					storeFirstDay={storeFirstDay}
					displayReportsLink={
						userData.data.role.type === USER_ROLES.manager
					}
					onDataUpdate={handleBatchUpdate}
					onBatchDelete={handleBatchDelete}
				/>
			) : (
				<Typography>
					Parduotuvėje nėra užregistruotų prekių partijų
				</Typography>
			)}
			<BatchDialog
				open={batchDialogOpen}
				onClose={handleBatchDialogClose}
				title="Nauja partija"
				data={{
					title: "",
					sellingStartDate: getNextWeekday(storeFirstDay.day),
				}}
				storeFirstDay={storeFirstDay}
				onSubmit={handleBatchCreate}
			/>
		</Box>
  );
};

export default BatchList;
