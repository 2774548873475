import { Routes, Route } from 'react-router-dom';

import BoxList from './features/BoxList';
import Box from './features/Box';

const Boxes = () => {
  return (
    <Routes>
      <Route index element={<BoxList />} />
      <Route path="/:boxId" element={<Box />} />
    </Routes>
  );
};

export default Boxes;
