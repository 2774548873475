import { DateTime } from "luxon";

import DEFAULT_TIMEZONE from "../../../../../../../../../utils/date/DEFAULT_TIMEZONE";

const makeSoldGoodsPriceCountsDetailedMapByDay = (soldGoods) =>
	soldGoods.reduce((categoryCountsMapByDay, soldGood) => {
		const day = DateTime.fromISO(soldGood.created_at).setZone(
			DEFAULT_TIMEZONE
		).weekday;

		if (categoryCountsMapByDay[day]) {
			if (categoryCountsMapByDay[day][`${soldGood.price}`]) {
				categoryCountsMapByDay[day][`${soldGood.price}`].count += 1;
				categoryCountsMapByDay[day][`${soldGood.price}`].actualPrice +=
					soldGood.actualPrice;
			} else {
				categoryCountsMapByDay[day][`${soldGood.price}`] = {
					count: 1,
					actualPrice: soldGood.actualPrice,
				};
			}
		} else {
			categoryCountsMapByDay[day] = {
				[`${soldGood.price}`]: {
					count: 1,
					actualPrice: soldGood.actualPrice,
				},
			};
		}

		return categoryCountsMapByDay;
	}, {});

export default makeSoldGoodsPriceCountsDetailedMapByDay;
