import { Routes, Route } from 'react-router-dom';

import BatchList from './features/BatchList';
import Batch from './features/Batch';

const Batches = () => {
  return (
    <Routes>
      <Route index element={<BatchList />} />
      <Route path="/:batchId/*" element={<Batch />} />
    </Routes>
  );
};

export default Batches;
