import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import formatMoney from '../../../../../../../../../../../../utils/currency/formatMoney';

import BoxCard from './components/BoxCard';

const Boxes = ({ data, totalGoods, totalPrice, onDataUpdate, onBoxDelete }) => {
  return (
    <Box>
      <Paper sx={{ mb: 2 }}>
        <Toolbar>
          <Typography>
            Partijoje{' '}
            <Box component="span" fontWeight="medium">
              {data.length}
            </Box>{' '}
            maišų,{' '}
            <Box component="span" fontWeight="medium">
              {totalGoods}
            </Box>{' '}
            prekių. Bendra vertė -{' '}
            <Box component="span" fontWeight="medium">
              {formatMoney(totalPrice)}
            </Box>
            .
          </Typography>
        </Toolbar>
      </Paper>
      <Box sx={{ '& > *:not(:last-child)': { mb: 1 } }}>
        {data.map((box) => (
          <BoxCard
            key={box.id}
            data={box}
            onDataUpdate={onDataUpdate}
            onDelete={onBoxDelete}
          />
        ))}
      </Box>
    </Box>
  );
};

Boxes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  totalGoods: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  onDataUpdate: PropTypes.func.isRequired,
  onBoxDelete: PropTypes.func.isRequired,
};

export default Boxes;
