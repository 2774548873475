import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const PageTitle = ({ children }) => {
  return (
    <Typography variant="h6" sx={{ wordWrap: 'break-word' }} gutterBottom>
      {children}
    </Typography>
  );
};

PageTitle.propTypes = {
  children: PropTypes.node,
};

export default PageTitle;
