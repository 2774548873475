import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import validationSchema from "./utils/validationSchema";
import {
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Switch,
} from "@mui/material";
import { useAuthContext } from "@rokasaitenta/react-auth-provider";
import useUser from "../../../../utils/api/data-hooks/useUser";
import USER_ROLES from "../../../../utils/api/USER_ROLES";
import CATEGORIES from "../../../../utils/api/CATEGORIES";
import { useRef, useState } from "react";

const GoodForm = ({ onSubmit }) => {
	const formUtils = useForm({
		defaultValues: { price: "", category: undefined },
		resolver: yupResolver(validationSchema),
	});

	const [authState, tokenValid] = useAuthContext();

	const userData = useUser(tokenValid && authState.user?.id, authState.token);

	const priceFieldBag = formUtils.register("price");
	const categoryFieldBag = formUtils.register("category");

	const handleSubmit = async (good) => {
		await onSubmit(good);
		formUtils.reset({ price: "" });
	};

	return (
		<form onSubmit={formUtils.handleSubmit(handleSubmit)}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					"& > *:not(:last-child)": { mr: 1 },
				}}
			>
				<FormControl sx={{ minWidth: 120 }}>
					<InputLabel id="category-label">Kategorija</InputLabel>
					<Select
						labelId="category-label"
						label="Kategorija"
						{...categoryFieldBag}
						inputRef={categoryFieldBag.ref}
						disabled={formUtils.formState.isSubmitting}
						defaultValue={CATEGORIES.other}
					>
						{Object.values(CATEGORIES).map((category) => (
							<MenuItem key={category} value={category}>
								{category}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					label="Kaina, EUR"
					// type="number" //! this forces the numbers to be integers, which is not what we want
					{...priceFieldBag}
					inputRef={priceFieldBag.ref}
					disabled={formUtils.formState.isSubmitting}
					error={!!formUtils.formState.errors["price"]}
					helperText={formUtils.formState.errors["price"]?.message}
				/>
				{formUtils.formState.isSubmitting ? (
					<CircularProgress />
				) : (
					<Button variant="contained" type="submit">
						Įtraukti
					</Button>
				)}
			</Box>
		</form>
	);
};

GoodForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default GoodForm;
