import { object, string, mixed } from "yup";
import DEFAULT_TIMEZONE from "../../../../../../../../../../../utils/date/DEFAULT_TIMEZONE";
import validateSellingStartDate from "./validateSellingStartDate";

const { DateTime } = require("luxon");

const makeValidationSchema = (storeFirstDay, todayDate) =>
	object({
		title: string().required("Pavadinimas yra privalomas"),
		sellingStartDate: mixed().test(
			"is-valid-selling-start-date",
			"Prekybos pradžios data privalo sutapti su vienos iš ateinančių prekybos savaičių pirmąja diena",
			(value, context) => {
				// NOTE validate sellingStartDate only if it was changed, because
				// previously valid value could now be invalid (as validity depends on
				//  current time) and prevent user from updating other values
				let newValue = value;
				try {
					if (!(newValue instanceof Date)) {
						newValue =
							DateTime.fromISO(newValue).setZone(
								DEFAULT_TIMEZONE
							);
					}
				} catch (error) {
					return false;
				}
				return (
					(typeof context.parent._currentSellingStartDate !==
						"undefined" &&
						context.parent._currentSellingStartDate.toMillis() ===
							newValue.toMillis()) ||
					validateSellingStartDate(newValue, storeFirstDay, todayDate)
				);
			}
		),
	});

export default makeValidationSchema;
