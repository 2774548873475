import useSWR from 'swr';

import ENDPOINTS from '../ENDPOINTS';

import fetcherWithAuth from './utils/fetcherWithAuth';

const useBatch = (id, token) =>
  useSWR(
    id && token ? [`${ENDPOINTS.batches}/${id}`, token] : null,
    fetcherWithAuth
  );

export default useBatch;
