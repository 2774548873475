import _ from "lodash";
import { DateTime } from "luxon";
import { useContext } from "react";
import { useAuthContext } from "@rokasaitenta/react-auth-provider";
import { useParams } from "react-router-dom";
import MUIBox from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import DEFAULT_TIMEZONE from "../../../../../../../../utils/date/DEFAULT_TIMEZONE";
import useStore from "../../../../../../../../utils/api/data-hooks/useStore";
import useBatch from "../../../../../../../../utils/api/data-hooks/useBatch";
import useSoldGoods from "../../../../../../../../utils/api/data-hooks/useSoldGoods";

import PageTitle from "../../../../../../../../components/PageTitle";
import CenteredCircularProgress from "../../../../../../../../components/CenteredCircularProgress";
import ErrorMessage from "../../../../../../../../components/ErrorMessage";
import { SelectedStoreContext } from "../../../../../../../../components/SelectedStoreProvider";

import orderDays from "./utils/orderDays";
import makeSoldGoodsPriceCountsMapByDay from "./utils/makeSoldGoodsPriceCountsMapByDay";
import makeSoldDailyReport from "./utils/makeSoldDailyReport";

import MainReport from "./components/MainReport";
import PriceTable from "./components/PriceTable";
import SoldGoodsWeekView from "./components/SoldGoodsWeekView";
import makeSoldByCategoryReport from "./utils/makeSoldByCategoryReport";
import makeSoldGoodsCategoryCountsMapByDay from "./utils/makeSoldGoodsCategoryCountsMapByDay";
import makeSoldGoodsPriceCountsDetailedMapByDay from "./utils/makeSoldGoodsPriceCountsDetailedMapByDay";

const Report = () => {
	const [{ token }] = useAuthContext();

	const { batchId } = useParams();

	const [storeId] = useContext(SelectedStoreContext);

	const storeData = useStore(storeId, token);

	const batchData = useBatch(batchId, token);

	const sellingStartDate =
		batchData.data &&
		DateTime.fromISO(batchData.data.sellingStartDate).setZone(
			DEFAULT_TIMEZONE
		);

	const soldGoodsData = useSoldGoods(
		storeId,
		token,
		sellingStartDate?.toMillis(),
		sellingStartDate?.plus({ week: 1 }).toMillis()
	);

	if (storeData.error) {
		return (
			<ErrorMessage>
				Nepavykos gauti parduotuvės duomenų. Pabandykite perkrauti
				puslapį.
			</ErrorMessage>
		);
	}

	if (batchData.error) {
		return (
			<ErrorMessage>
				Nepavykos gauti partijos duomenų. Pabandykite perkrauti puslapį.
			</ErrorMessage>
		);
	}

	if (soldGoodsData.error) {
		return (
			<ErrorMessage>
				Nepavykos gauti parduotų prekių sąrašo. Pabandykite perkrauti
				puslapį.
			</ErrorMessage>
		);
	}

	if (!storeData.data || !batchData.data || !soldGoodsData.data) {
		return <CenteredCircularProgress />;
	}

	const goodsCount = batchData.data.goods.length;
	const goodsTotalPrice = _.sumBy(batchData.data.goods, "price");
	const goodsPriceCounts = _.countBy(batchData.data.goods, "price");

	const remainderGoodsCount = batchData.data.remainderGoods.length;
	const remainderGoodsTotalPrice = _.sumBy(
		batchData.data.remainderGoods,
		"price"
	);
	const remainderGoodsPriceCounts = _.countBy(
		batchData.data.remainderGoods,
		"price"
	);

	const soldGoodsPriceCounts = _.countBy(soldGoodsData.data, "price");

	const prices = _.sortBy(
		_.uniq([
			..._.keys(goodsPriceCounts),
			..._.keys(remainderGoodsPriceCounts),
			..._.keys(soldGoodsPriceCounts),
		]).map((priceKey) => parseFloat(priceKey, 10))
	);

	const soldGoodsPriceCountsMapByDay = makeSoldGoodsPriceCountsMapByDay(
		soldGoodsData.data
	);

	const soldGoodsCategoryCountsMapByDay = makeSoldGoodsCategoryCountsMapByDay(
		soldGoodsData.data
	);
	const soldGoodsPriceCountsDetailedMapByDay =
		makeSoldGoodsPriceCountsDetailedMapByDay(soldGoodsData.data);

	const soldDailyReport = makeSoldDailyReport(soldGoodsData.data);

	const soldByCategoryReport = makeSoldByCategoryReport(soldGoodsData.data);

	const soldGoodDays = orderDays(
		storeData.data.dayConfigs.find(({ first }) => first).day,
		_.keys(soldGoodsPriceCountsMapByDay).map((dayString) =>
			parseFloat(dayString, 10)
		)
	);

	return (
		<MUIBox>
			<PageTitle>"{batchData.data.title}" ataskaita</PageTitle>
			{prices.length > 0 ? (
				<MUIBox sx={{ "& > *:not(:last-child)": { mb: 4 } }}>
					<MainReport
						days={soldGoodDays}
						goodsCount={goodsCount}
						goodsTotalPrice={goodsTotalPrice}
						soldDailyReport={soldDailyReport}
						soldByCategoryReport={soldByCategoryReport}
						remainderGoodsCount={remainderGoodsCount}
						remainderGoodsTotalPrice={remainderGoodsTotalPrice}
					/>
					<PriceTable
						prices={prices}
						goodsPriceCounts={goodsPriceCounts}
						remainderGoodsPriceCounts={remainderGoodsPriceCounts}
						soldGoodsPriceCounts={soldGoodsPriceCounts}
					/>
					<SoldGoodsWeekView
						days={soldGoodDays}
						priceCountsMapByDay={
							soldGoodsPriceCountsDetailedMapByDay
						}
						categoryCountsByDay={soldGoodsCategoryCountsMapByDay}
					/>
				</MUIBox>
			) : (
				<Typography>
					Partijoje nėra užregistruota jokių prekių.
				</Typography>
			)}
		</MUIBox>
	);
};

export default Report;
