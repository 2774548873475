const makeSoldByCategoryReport = (soldGoods) =>
	soldGoods.reduce(
		(dailyReport, soldGood) => {
			let category = soldGood.category;

			dailyReport.total.count++;
			dailyReport.total.totalPrice += soldGood.price;
			dailyReport.total.totalActualPrice += soldGood.actualPrice;

			if (!isNaN(category)) {
				category = "Nėra kategorijos";
			}
            
			if (dailyReport.daily[category]) {
				dailyReport.daily[category].count++;
				dailyReport.daily[category].totalPrice += soldGood.price;
				dailyReport.daily[category].totalActualPrice +=
					soldGood.actualPrice;
			} else {
				dailyReport.daily[category] = {
					count: 1,
					totalPrice: soldGood.price,
					totalActualPrice: soldGood.actualPrice,
					category: category,
				};
			}

			return dailyReport;
		},
		{ daily: {}, total: { count: 0, totalPrice: 0, totalActualPrice: 0 } }
	);

export default makeSoldByCategoryReport;
