import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';

import formatWeight from '../../../../../../../../../../../../../../utils/weight/formatWeight';

import ConfirmationDialog from '../../../../../../../../../../../../../../components/ConfirmationDialog';

import BoxDialog from '../../../BoxDialog';

const BoxCard = ({ data, onDataUpdate, onDelete }) => {
  const navigate = useNavigate();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleTitleClick = () => {
    navigate(`${data.id}`);
  };

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditDialogSubmit = async (updateData) => {
    if (await onDataUpdate(data.id, updateData)) {
      setEditDialogOpen(false);
    }
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    setDeleteDialogOpen(false);

    setDeleting(true);
    await onDelete(data.id);
    setDeleting(false);
  };

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h5"
          sx={{ wordWrap: 'break-word' }}
          onClick={handleTitleClick}
        >
          {data.title}
        </Typography>
        <Typography>{formatWeight(data.weight)}</Typography>
      </CardContent>
      <CardActions>
        {deleting ? (
          <CircularProgress />
        ) : (
          <>
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </CardActions>
      <BoxDialog
        title={data.title}
        data={data}
        onSubmit={handleEditDialogSubmit}
        open={editDialogOpen}
        onClose={handleEditDialogClose}
      />
      <ConfirmationDialog
        title={`Ar tikrai norite ištrinti maišą "${data.title}"?`}
        confirmButtonText="Ištrinti"
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onCancel={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
      />
    </Card>
  );
};

BoxCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
  }).isRequired,
  onDataUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BoxCard;
