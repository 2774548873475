import axiosInstance from '../../axiosInstance';

const fetcherWithAuth = async (url, token) => {
  const { data } = await axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export default fetcherWithAuth;
