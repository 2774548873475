import useSWR from 'swr';

import ENDPOINTS from '../ENDPOINTS';

import fetcherWithAuth from './utils/fetcherWithAuth';

const useSoldGoods = (storeId, token, startDate, endDate) =>
  useSWR(
    storeId && token && startDate && endDate
      ? [
          `${ENDPOINTS.soldGoods}?store=${storeId}&created_at_gte=${startDate}&created_at_lt=${endDate}&_limit=-1`,
          token,
        ]
      : null,
    fetcherWithAuth
  );

export default useSoldGoods;
