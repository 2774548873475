import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import BoxForm, { validationSchema } from './components/BoxForm';

const BoxDialog = ({ title, data, onSubmit, onClose, ...restProps }) => {
  const formUtils = useForm({
    defaultValues: data,
    resolver: yupResolver(validationSchema),
  });

  const handleCloseClick = () => {
    onClose();
  };

  return (
    <Dialog onClose={onClose} {...restProps}>
      <DialogTitle sx={{ wordWrap: 'break-word' }}>{title}</DialogTitle>
      <form onSubmit={formUtils.handleSubmit(onSubmit)}>
        <DialogContent>
          <BoxForm formUtils={formUtils} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={handleCloseClick}
            disabled={formUtils.formState.isSubmitting}
          >
            Uždaryti
          </Button>
          {formUtils.formState.isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" type="submit">
              Išsaugoti
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

BoxDialog.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    weight: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number]),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BoxDialog;
