import { createContext } from 'react';
import PropTypes from 'prop-types';
import useStateLocalStorage from '@rokasaitenta/react-use-state-local-storage';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';

const SELECTED_STORE_KEY = 'selected_store';

export const SelectedStoreContext = createContext();

const SelectedStoreProvider = ({ children }) => {
  const [{ user }] = useAuthContext();

  const [store, setStore] = useStateLocalStorage(
    user ? `${SELECTED_STORE_KEY}_${user.id}` : null
  );

  return (
    <SelectedStoreContext.Provider value={[store, setStore]}>
      {children}
    </SelectedStoreContext.Provider>
  );
};

SelectedStoreProvider.propTypes = {
  children: PropTypes.node,
};

export default SelectedStoreProvider;
