import { useContext } from 'react';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import useUser from '../../utils/api/data-hooks/useUser';

import { SelectedStoreContext } from '../SelectedStoreProvider';

const StoreSelector = () => {
  const [authState, tokenValid] = useAuthContext();

  const [storeId, setStoreId] = useContext(SelectedStoreContext);

  const userData = useUser(tokenValid && authState.user?.id, authState.token);

  const handleStoreChange = ({ target: { value } }) => setStoreId(value);

  if (userData.error) {
    return null;
  }

  if (!userData.data) {
    <CircularProgress />;
  }

  return (
    <Box sx={{ width: 100 }}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="demo-simple-select-label">Parduotuvė</InputLabel>
        <Select
          id="demo-simple-select"
          labelId="demo-simple-select-label"
          label="Parduotuvė"
          value={storeId ?? ''}
          onChange={handleStoreChange}
        >
          {userData.data.stores.map((store) => (
            <MenuItem key={store.id} value={store.id}>
              {store.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StoreSelector;
