import _ from "lodash";
import { Info } from "luxon";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import formatMoney from "../../../../../../../../../../utils/currency/formatMoney";

const weekdays = Info.weekdays("long", {
	locale: "lt-LT",
	numberingSystem: "europe",
});

const MainReport = ({
	days,

	goodsCount,
	goodsTotalPrice,

	soldDailyReport,
	soldByCategoryReport,

	remainderGoodsCount,
	remainderGoodsTotalPrice,
}) => {
	return (
		<Paper>
			<Toolbar>
				<Typography fontWeight="medium">Bendra ataskaita</Typography>
			</Toolbar>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align="left"></TableCell>
							<TableCell align="right">Vnt.</TableCell>
							<TableCell align="right">EUR</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell align="left">
								<Typography fontWeight="medium">
									Įkainota
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium">
									{goodsCount}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium">
									{formatMoney(goodsTotalPrice, false)}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">
								<Typography fontWeight="medium">
									Parduota
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium">
									{soldDailyReport.total.count}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium">
									{formatMoney(
										soldDailyReport.total.totalPrice,
										false
									)}{" "}
									(
									{formatMoney(
										soldDailyReport.total.totalActualPrice,
										false
									)}
									)
								</Typography>
							</TableCell>
						</TableRow>
						{days.map((day, index) => {
							return (
								<TableRow key={`${day}${index}`}>
									<TableCell align="left">
										{_.capitalize(weekdays[day - 1])}
									</TableCell>
									<TableCell align="right">
										{soldDailyReport.daily[day].count}
									</TableCell>
									<TableCell align="right">
										{formatMoney(
											soldDailyReport.daily[day]
												.totalPrice,
											false
										)}{" "}
										(
										{formatMoney(
											soldDailyReport.daily[day]
												.totalActualPrice,
											false
										)}
										)
									</TableCell>
								</TableRow>
							);
						})}
						<TableRow>
							<TableCell align="left">
								<Typography fontWeight="medium">
									Kategorijos
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium"></Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium"></Typography>
							</TableCell>
						</TableRow>
						{Object.values(soldByCategoryReport.daily).map(
							(value, index) => {
								return (
									<TableRow key={`${value.category}${index}`}>
										<TableCell align="left">
											{value.category}
										</TableCell>
										<TableCell align="right">
											{value.count}
										</TableCell>
										<TableCell align="right">
											{formatMoney(
												value.totalPrice,
												false
											)}{" "}
											(
											{formatMoney(
												value.totalActualPrice,
												false
											)}
											)
										</TableCell>
									</TableRow>
								);
							}
						)}
						<TableRow>
							<TableCell align="left">
								<Typography fontWeight="medium">
									Grąžinta
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium">
									{remainderGoodsCount}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium">
									{formatMoney(
										remainderGoodsTotalPrice,
										false
									)}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">
								<Typography fontWeight="medium">
									Skirtumas
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium">
									{goodsCount -
										soldDailyReport.total.count -
										remainderGoodsCount}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography fontWeight="medium">
									{formatMoney(
										goodsTotalPrice -
											soldDailyReport.total.totalPrice -
											remainderGoodsTotalPrice,
										false
									)}{" "}
									(
									{formatMoney(
										goodsTotalPrice -
											soldDailyReport.total
												.totalActualPrice -
											remainderGoodsTotalPrice,
										false
									)}
									)
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

MainReport.propTypes = {
	days: PropTypes.arrayOf(PropTypes.number).isRequired,

	goodsCount: PropTypes.number.isRequired,
	goodsTotalPrice: PropTypes.number.isRequired,

	soldDailyReport: PropTypes.shape({
		total: PropTypes.shape({
			count: PropTypes.number.isRequired,
			totalPrice: PropTypes.number.isRequired,
			totalActualPrice: PropTypes.number.isRequired,
		}).isRequired,
		daily: PropTypes.objectOf(
			PropTypes.shape({
				count: PropTypes.number.isRequired,
				totalPrice: PropTypes.number.isRequired,
				totalActualPrice: PropTypes.number.isRequired,
			})
		).isRequired,
	}).isRequired,

	soldByCategoryReport: PropTypes.shape({
		total: PropTypes.shape({
			count: PropTypes.number.isRequired,
			totalPrice: PropTypes.number.isRequired,
			totalActualPrice: PropTypes.number.isRequired,
		}).isRequired,
		daily: PropTypes.objectOf(
			PropTypes.shape({
				count: PropTypes.number.isRequired,
				totalPrice: PropTypes.number.isRequired,
				totalActualPrice: PropTypes.number.isRequired,
			})
		).isRequired,
	}).isRequired,

	remainderGoodsCount: PropTypes.number.isRequired,
	remainderGoodsTotalPrice: PropTypes.number.isRequired,
};

export default MainReport;
