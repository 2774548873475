import { DateTime } from 'luxon';
import { useState, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import DEFAULT_TIMEZONE from '../../../../utils/date/DEFAULT_TIMEZONE';
import ENDPOINTS from '../../../../utils/api/ENDPOINTS';
import axiosInstance from '../../../../utils/api/axiosInstance';
import useStore from '../../../../utils/api/data-hooks/useStore';

import CenteredCircularProgress from '../../../../components/CenteredCircularProgress';
import ErrorMessage from '../../../../components/ErrorMessage';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { SelectedStoreContext } from '../../../../components/SelectedStoreProvider';
import GoodInput from '../../../../components/GoodInput';

import calculateActualPrice from './utils/calculateActualPrice';

import GoodTable from './components/GoodTable';

const Order = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [{ token }] = useAuthContext();

  const [storeId] = useContext(SelectedStoreContext);

  const storeData = useStore(storeId, token);

  const [goods, setGoods] = useState([]);

  const [completeOrderDialogOpen, setCompleteOrderDialogOpen] = useState(false);
  const [completeOrderLoading, setCompleteOrderLoading] = useState(false);

  const weekday = DateTime.now().setZone(DEFAULT_TIMEZONE).weekday;
  const { discount, goodsPrice } =
    storeData.data?.dayConfigs.find((dayConfig) => dayConfig.day === weekday) ??
    {};

  // NOTE react-swr periodically updates values, thus if day config was
  // changed in the meantime all existing goods must be updated
  useEffect(() => {
    setGoods((goods) =>
      goods.map((good) => ({
        ...good,
        actualPrice: calculateActualPrice(good.price, goodsPrice, discount, good.category),
      }))
    );
  }, [discount, goodsPrice]);

  const handleAddGood = (createGood) => {
    setGoods((goods) => [
      {
        ...createGood,
        actualPrice: calculateActualPrice(
          createGood.price,
          goodsPrice,
          discount,
          createGood.category
        ),
      },
      ...goods,
    ]);
  };

  const handleDeleteGood = async (deleteGood) => {
    setGoods((goods) => goods.filter((good) => good !== deleteGood));
  };

  const handleCancelOrderClick = () => {
    setGoods([]);
  };

  const handleCompleteOrderClick = () => {
    setCompleteOrderDialogOpen(true);
  };

  const handleCompleteOrderDialogClose = () => {
    setCompleteOrderDialogOpen(false);
  };

  const handleCompleteOrderConfirm = async () => {
    setCompleteOrderDialogOpen(false);
    setCompleteOrderLoading(true);

    try {
      await axiosInstance.post(
        ENDPOINTS.orders,
        {
          store: storeId,
          soldGoods: goods,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGoods([]);

      enqueueSnackbar('Pardavimas sėkmingai užbaigtas', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko užbaigti pardavimo. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }

    setCompleteOrderLoading(false);
  };

  if (storeData.error) {
    return (
      <ErrorMessage>
        Nepavyko gauti parduotuvės informacijos. Pabandykite perkrauti puslapį.
      </ErrorMessage>
    );
  }

  if (!storeData.data) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box>
      <Box sx={{ mb: 1 }}>
        <GoodInput onGoodInput={handleAddGood} />
      </Box>
      {goods.length > 0 && (
        <>
          <Box
            sx={{
              mb: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              '& > *:not(:last-child)': { mr: 1 },
            }}
          >
            <Button
              variant="text"
              onClick={handleCancelOrderClick}
              disabled={completeOrderLoading}
            >
              Atšaukti visas prekes
            </Button>
            {completeOrderLoading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" onClick={handleCompleteOrderClick}>
                Užbaigti pardavimą
              </Button>
            )}
          </Box>
          <GoodTable goods={goods} onDeleteGood={handleDeleteGood} />
        </>
      )}
      <ConfirmationDialog
        title={`Ar tikrai norite užbaigti pardavimą?`}
        confirmButtonText="Užbaigti pardavimą"
        open={completeOrderDialogOpen}
        onClose={handleCompleteOrderDialogClose}
        onCancel={handleCompleteOrderDialogClose}
        onConfirm={handleCompleteOrderConfirm}
      />
    </Box>
  );
};

export default Order;
