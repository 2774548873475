import { DateTime } from 'luxon';

import DEFAULT_TIMEZONE from './DEFAULT_TIMEZONE';

const getNextWeekday = (weekday) => {
  const todayDate = DateTime.now().setZone(DEFAULT_TIMEZONE).startOf('day');
  return todayDate.weekday < weekday
    ? todayDate.set({ weekday })
    : todayDate.plus({ weeks: 1 }).set({ weekday });
};

export default getNextWeekday;
