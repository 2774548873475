import DEFAULT_TIMEZONE from "../../../../../../../../../../../utils/date/DEFAULT_TIMEZONE";
const { DateTime } = require("luxon");

const validateSellingStartDate = (
	sellingStartDate,
	storeFirstDay,
	todayDate
) => {
	try {
		if (!(sellingStartDate instanceof Date)) {
			sellingStartDate =
				DateTime.fromISO(sellingStartDate).setZone(DEFAULT_TIMEZONE);
		}
	} catch (error) {
		return false;
	}

	return (
		sellingStartDate.weekday === storeFirstDay &&
		sellingStartDate.startOf("day") > todayDate
	);
};

export default validateSellingStartDate;
