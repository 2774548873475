import _ from "lodash";
import CATEGORIES from "../../../../../utils/api/CATEGORIES";

// ! NOTE: if changing update in FE too
const CONSTANT_PRICE_CATEGORIES = ["Maiseliai", "Rankinukai"];

// ! NOTE if changing update in BE too
const calculateActualPrice = (price, goodsPrice, discount, category) => {
	if (category && CONSTANT_PRICE_CATEGORIES.includes(category)) {
		return price;
	}

	// ! This category is unique and should only be typed here manually
	if (category === "Speckategorija") {
		if (goodsPrice && price === 0.5) {
			return goodsPrice;
		}
		if (price <= 1) {
			return price;
		}
	}

	// all goods below 1€ are not discounted, unless it's last day of sale
	if (price < 1) {
		if (goodsPrice > price) return price;

		if (goodsPrice) return goodsPrice;

		return price;
	}

	return (
		goodsPrice ?? (discount ? _.round(price * (1 - discount), 2) : price)
	);
};

export default calculateActualPrice;
