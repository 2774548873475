import { DateTime } from "luxon";

import DEFAULT_TIMEZONE from "../../../../../../../../../utils/date/DEFAULT_TIMEZONE";

const makeSoldGoodsPriceCountsMapByDay = (soldGoods) =>
	soldGoods.reduce((priceCountsMapByDay, soldGood) => {
		const day = DateTime.fromISO(soldGood.created_at).setZone(
			DEFAULT_TIMEZONE
		).weekday;

		if (priceCountsMapByDay[day]) {
			if (priceCountsMapByDay[day][`${soldGood.price}`]) {
				priceCountsMapByDay[day][`${soldGood.price}`]++;
			} else {
				priceCountsMapByDay[day][`${soldGood.price}`] = 1;
			}
		} else {
			priceCountsMapByDay[day] = {
				[`${soldGood.price}`]: 1,
			};
		}

		return priceCountsMapByDay;
	}, {});

export default makeSoldGoodsPriceCountsMapByDay;
