import React, { createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import useStateLocalStorage from '@rokasaitenta/react-use-state-local-storage';

import isAuthTokenValid from './utils/isAuthTokenValid';

const DEFAULT_LOCAL_STORAGE_KEY = 'auth_state';

const DEFAULT_STATE_VALUE = { user: undefined, token: undefined };
const DEFAULT_VALUE = [
  DEFAULT_STATE_VALUE,
  false,
  ({ user, token }) => {},
  () => {},
];
const AuthContext = createContext(DEFAULT_VALUE);

const AuthProvider = ({ localStorageKey, children }) => {
  const [state, setState] = useStateLocalStorage(
    localStorageKey,
    DEFAULT_STATE_VALUE
  );

  const signIn = useCallback(({ user, token }) => {
    setState({ user, token });
  }, []);

  const signOut = useCallback(() => {
    setState(DEFAULT_STATE_VALUE);
  }, []);

  const tokenValid = isAuthTokenValid(state?.token);

  const value = [state, tokenValid, signIn, signOut];

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.defaultProps = {
  localStorageKey: DEFAULT_LOCAL_STORAGE_KEY,
};

AuthProvider.propTypes = {
  localStorageKey: PropTypes.string,
  children: PropTypes.node,
};

export default AuthProvider;

export const useAuthContext = () => useContext(AuthContext);
