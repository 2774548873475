import { useState } from 'react';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import Box from '@mui/material/Box';

import ENDPOINTS from '../../utils/api/ENDPOINTS';
import axiosInstance from '../../utils/api/axiosInstance';

import ErrorMessage from '../ErrorMessage';

import INVALID_CREDENTIALS_MESSAGE from './utils/INVALID_CREDENTIALS_MESSAGE';

import Form from './components/Form';

const SignIn = () => {
  const [, , signIn] = useAuthContext();

  const [error, setError] = useState();

  const handleSubmit = async (credentials) => {
    setError(null);

    try {
      const {
        data: { user, jwt },
      } = await axiosInstance.post(ENDPOINTS.auth, credentials);
      signIn({ user: { id: user.id }, token: jwt });
    } catch (error) {
      if (
        error.response?.data?.message?.[0]?.messages?.[0]?.message ===
        INVALID_CREDENTIALS_MESSAGE
      ) {
        setError(
          'Neteisingi prisijungimo duomenys. Patikslinkite ir bandykite dar kartą.'
        );
      } else {
        setError('Prisijungti nepavyko. Bandykite dar kartą.');
      }
    }
  };

  return (
    <Box>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Form onSubmit={handleSubmit} />
    </Box>
  );
};

export default SignIn;
