import { Routes, Route } from 'react-router-dom';

import Boxes from './features/Boxes';
import RemainderGoods from './features/RemainderGoods';
import Report from './features/Report';

const Batch = () => {
  return (
    <Routes>
      <Route path="/boxes/*" element={<Boxes />} />
      <Route path="/remainder-goods" element={<RemainderGoods />} />
      <Route path="/report" element={<Report />} />
    </Routes>
  );
};

export default Batch;
