import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import DEFAULT_TIMEZONE from '../../../../../../../../../../utils/date/DEFAULT_TIMEZONE';

import ConfirmationDialog from '../../../../../../../../../../components/ConfirmationDialog';

import BatchDialog from '../../../BatchDialog';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import useUser from '../../../../../../../../../../utils/api/data-hooks/useUser';
import USER_ROLES from '../../../../../../../../../../utils/api/USER_ROLES';

const BatchCard = ({
  data,
  storeFirstDay,
  displayReportsLink,
  onDataUpdate,
  onDelete,
}) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [authState, tokenValid] = useAuthContext();
  const userData = useUser(tokenValid && authState.user?.id, authState.token);

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditDialogSubmit = async (updateData) => {
    if (await onDataUpdate(data.id, updateData)) {
      setEditDialogOpen(false);
    }
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    setDeleteDialogOpen(false);

    setDeleting(true);
    await onDelete(data.id);
    setDeleting(false);
  };

  return (
		<Card>
			<CardContent>
				<Box sx={{ mb: 2 }}>
					<Typography variant="h5" sx={{ wordWrap: "break-word" }}>
						{data.title}
					</Typography>
					<Typography>
						{DateTime.fromISO(data.sellingStartDate)
							.setZone(DEFAULT_TIMEZONE)
							.toLocaleString(DateTime.DATE_SHORT)}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						"& > *:not(:last-child)": { mb: 2 },
					}}
				>
					{userData.data.role.type !== USER_ROLES.cashiers && (
						<Link to={`${data.id}/boxes`}>įkainavimas</Link>
					)}
					<Link to={`${data.id}/remainder-goods`}>likučiai</Link>
					{displayReportsLink && (
						<Link to={`${data.id}/report`}>ataskaita</Link>
					)}
				</Box>
			</CardContent>
			<CardActions>
				{deleting ? (
					<CircularProgress />
				) : (
					<>
						{userData.data.role.type !== USER_ROLES.cashiers && (
							<>
								<IconButton onClick={handleEditClick}>
									<EditIcon />
								</IconButton>
								<IconButton onClick={handleDeleteClick}>
									<DeleteIcon />
								</IconButton>{" "}
							</>
						)}
					</>
				)}
			</CardActions>
			<BatchDialog
				title={data.title}
				data={{
					...data,
					_currentSellingStartDate: DateTime.fromISO(
						data.sellingStartDate
					).setZone(DEFAULT_TIMEZONE),
					sellingStartDate: DateTime.fromISO(
						data.sellingStartDate
					).setZone(DEFAULT_TIMEZONE),
				}}
				storeFirstDay={storeFirstDay}
				onSubmit={handleEditDialogSubmit}
				open={editDialogOpen}
				onClose={handleEditDialogClose}
			/>
			<ConfirmationDialog
				title={`Ar tikrai norite ištrinti partiją "${data.title}"?`}
				confirmButtonText="Ištrinti"
				open={deleteDialogOpen}
				onClose={handleDeleteDialogClose}
				onCancel={handleDeleteDialogClose}
				onConfirm={handleDeleteConfirm}
			/>
		</Card>
  );
};

BatchCard.defaultProps = {
  displayReportsLink: false,
};

BatchCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    sellingStartDate: PropTypes.string.isRequired,
  }).isRequired,
  storeFirstDay: PropTypes.any.isRequired,
  displayReportsLink: PropTypes.bool,
  onDataUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BatchCard;
