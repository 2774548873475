import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import BatchCard from './components/BatchCard';

const Batches = ({
  data,
  storeFirstDay,
  displayReportsLink,
  onDataUpdate,
  onBatchDelete,
}) => {
  return (
    <Box sx={{ '& > *:not(:last-child)': { mb: 1 } }}>
      {data.map((batch) => (
        <BatchCard
          key={batch.id}
          data={batch}
          storeFirstDay={storeFirstDay}
          displayReportsLink={displayReportsLink}
          onDataUpdate={onDataUpdate}
          onDelete={onBatchDelete}
        />
      ))}
    </Box>
  );
};

Batches.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  storeFirstDay: PropTypes.any.isRequired,
  displayReportsLink: PropTypes.bool,
  onDataUpdate: PropTypes.func.isRequired,
  onBatchDelete: PropTypes.func.isRequired,
};

export default Batches;
