import useSWR from 'swr';

import ENDPOINTS from '../ENDPOINTS';

import fetcherWithAuth from './utils/fetcherWithAuth';

const useBatches = (storeId, token) =>
  useSWR(
    storeId && token
      ? [`${ENDPOINTS.batches}?store=${storeId}&_limit=-1`, token]
      : null,
    fetcherWithAuth
  );

export default useBatches;
