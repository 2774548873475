import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import { useParams } from 'react-router-dom';
import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import formatMoney from '../../../../../../../../../../utils/currency/formatMoney';
import ENDPOINTS from '../../../../../../../../../../utils/api/ENDPOINTS';
import axiosInstance from '../../../../../../../../../../utils/api/axiosInstance';
import useBox from '../../../../../../../../../../utils/api/data-hooks/useBox';

import { SelectedStoreContext } from '../../../../../../../../../../components/SelectedStoreProvider';
import PageTitle from '../../../../../../../../../../components/PageTitle';
import CenteredCircularProgress from '../../../../../../../../../../components/CenteredCircularProgress';
import ErrorMessage from '../../../../../../../../../../components/ErrorMessage';
import GoodInput from '../../../../../../../../../../components/GoodInput';

import GoodTable from './components/GoodTable';

const Box = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [{ token }] = useAuthContext();

  const { batchId, boxId } = useParams();

  const [storeId] = useContext(SelectedStoreContext);

  const boxData = useBox(boxId, token);

  const handleCreateGood = async (createGood) => {
    try {
      await axiosInstance.post(
        ENDPOINTS.goods,
        {
          ...createGood,
          box: parseInt(boxId, 10),
          batch: parseInt(batchId, 10),
          store: storeId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      boxData.mutate();
      enqueueSnackbar(
        `Prekė pridėta sėkmingai. Kaina - ${formatMoney(createGood.price)}.`,
        {
          variant: 'success',
        }
      );
    } catch (error) {
      enqueueSnackbar('Nepavyko pridėti prekės. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  const handleDeleteGood = async (deleteGood) => {
    try {
      await axiosInstance.delete(`${ENDPOINTS.goods}/${deleteGood.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      boxData.mutate();
      enqueueSnackbar('Prekė ištrinta sėkmingai', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko ištrinti prekės. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  if (boxData.error) {
    return (
      <ErrorMessage>
        Nepavyko gauti maišo prekių sąrašo. Pabandykite perkrauti puslapį.
      </ErrorMessage>
    );
  }

  if (!boxData.data) {
    return <CenteredCircularProgress />;
  }

  return (
    <MUIBox>
      <PageTitle>Maišas "{boxData.data.title}"</PageTitle>
      <MUIBox sx={{ mb: 1 }}>
        <GoodInput onGoodInput={handleCreateGood} />
      </MUIBox>
      {boxData.data.goods.length > 0 ? (
        <GoodTable goods={boxData.data.goods} onDeleteGood={handleDeleteGood} />
      ) : (
        <Typography>Maiše nėra prekių</Typography>
      )}
    </MUIBox>
  );
};

export default Box;
