import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import { useParams } from 'react-router-dom';
import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import formatMoney from '../../../../../../../../utils/currency/formatMoney';
import ENDPOINTS from '../../../../../../../../utils/api/ENDPOINTS';
import axiosInstance from '../../../../../../../../utils/api/axiosInstance';
import useBatch from '../../../../../../../../utils/api/data-hooks/useBatch';

import { SelectedStoreContext } from '../../../../../../../../components/SelectedStoreProvider';
import PageTitle from '../../../../../../../../components/PageTitle';
import CenteredCircularProgress from '../../../../../../../../components/CenteredCircularProgress';
import ErrorMessage from '../../../../../../../../components/ErrorMessage';
import GoodInput from '../../../../../../../../components/GoodInput';

import GoodTable from './components/GoodTable';

const RemainderGoods = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [{ token }] = useAuthContext();

  const { batchId } = useParams();

  const [storeId] = useContext(SelectedStoreContext);

  const batchData = useBatch(batchId, token);

  const handleCreateRemainderGood = async (createRemainderGood) => {
    try {
      await axiosInstance.post(
        ENDPOINTS.remainderGoods,
        {
          ...createRemainderGood,
          batch: parseInt(batchId, 10),
          store: storeId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      batchData.mutate();
      enqueueSnackbar(
        `Prekė pridėta sėkmingai. Kaina - ${formatMoney(
          createRemainderGood.price
        )}.`,
        {
          variant: 'success',
        }
      );
    } catch (error) {
      enqueueSnackbar('Nepavyko pridėti prekės. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  const handleDeleteRemainderGood = async (deleteRemainderGood) => {
    try {
      await axiosInstance.delete(
        `${ENDPOINTS.remainderGoods}/${deleteRemainderGood.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      batchData.mutate();
      enqueueSnackbar('Prekė ištrinta sėkmingai', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko ištrinti prekės. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  if (batchData.error) {
    return (
      <ErrorMessage>
        Nepavyko gauti partijos neparduotų prekių sąrašo. Pabandykite perkrauti
        puslapį.
      </ErrorMessage>
    );
  }

  if (!batchData.data) {
    return <CenteredCircularProgress />;
  }

  return (
    <MUIBox>
      <PageTitle>"{batchData.data.title}" likučiai</PageTitle>
      <MUIBox sx={{ mb: 1 }}>
        <GoodInput onGoodInput={handleCreateRemainderGood} />
      </MUIBox>
      {batchData.data.remainderGoods.length > 0 ? (
        <GoodTable
          goods={batchData.data.remainderGoods}
          onDeleteGood={handleDeleteRemainderGood}
        />
      ) : (
        <Typography>Partijoje nėra neparduotų prekių.</Typography>
      )}
    </MUIBox>
  );
};

export default RemainderGoods;
