import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import GoodForm from "./components/GoodForm";
import { MenuItem, Select, Switch } from "@mui/material";
import CATEGORIES from "../../utils/api/CATEGORIES";

const INPUT_MODES = {
	scan: "scan",
	manual: "manual",
};

const ACCEPTABLE_PRICE_KEYS_REGEX = /^[0-9.]$/;
const ACCEPTABLE_CATEGORY_KEYS_REGEX = /^[a-zA-Z]$/;

const GoodInput = ({ onGoodInput }) => {
	const [inputMode, setInputMode] = useState(null);

	const [handlingGoodInput, setHandlingGoodInput] = useState(false);
	const [categoryValue, setCategoryValue] = useState(CATEGORIES.other);
	const [checkedLockedCategory, setCheckedLockedCategory] = useState(false);

	useEffect(() => {
		if (inputMode === INPUT_MODES.scan && !handlingGoodInput) {
			let collectedPriceValue = "";
			let collectedCategoryValue = "";
			let isPricedLocked = false;
			let isCategoryLocked = false;

			const handlePriceInput = async (event) => {
				event.preventDefault();
				event.stopPropagation();

				if (checkedLockedCategory && categoryValue) {
					collectedCategoryValue = categoryValue;
				}

				const { key } = event;
				if (key === "Enter") {
					const price = parseFloat(collectedPriceValue);

					let category = collectedCategoryValue;
					if (isPricedLocked && !category) category = "Kita";

					if (!price || !category) {
						if (price) isPricedLocked = true;
						if (category) isCategoryLocked = true;
						return;
					}

					if (!isNaN(price)) {
						setHandlingGoodInput(true);

						await onGoodInput({ price, category });

						setHandlingGoodInput(false);
					}

					collectedPriceValue = "";
					collectedCategoryValue = "";
					isPricedLocked = false;
					isCategoryLocked = false;
				} else {
					if (
						ACCEPTABLE_PRICE_KEYS_REGEX.test(key) &&
						!isPricedLocked
					) {
						collectedPriceValue += key;
					}

					if (
						!checkedLockedCategory &&
						ACCEPTABLE_CATEGORY_KEYS_REGEX.test(key)
					) {
						if (isCategoryLocked) {
							collectedCategoryValue = "";
							isCategoryLocked = false;
						}
						collectedCategoryValue += key;
					}
				}
			};

			window.addEventListener("keydown", handlePriceInput);

			return () =>
				window.removeEventListener("keydown", handlePriceInput);
		}
	}, [
		inputMode,
		handlingGoodInput,
		onGoodInput,
		checkedLockedCategory,
		categoryValue,
	]);

	const handleScanModeClick = () => {
		setInputMode(INPUT_MODES.manual);
	};

	const handleManualModeClick = () => {
		setInputMode(INPUT_MODES.scan);
	};

	const handleTurnOffClick = () => {
		setInputMode(null);
	};

	const handleManualSubmit = async (good) => {
		const price = parseFloat(good.price);
		const category = good.category;
		if (!isNaN(price)) {
			setHandlingGoodInput(true);

			await onGoodInput({ price, category });

			setHandlingGoodInput(false);
		}
	};

	return (
		<Box>
			<Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
				{inputMode === null && (
					<Typography>
						Pasirinkite, ar norite skenuoti prekes, ar įvesti
						rankiniu būdu.
					</Typography>
				)}
				{inputMode === INPUT_MODES.scan &&
					(handlingGoodInput ? (
						<CircularProgress />
					) : (
						<>
							<Typography
								sx={{
									color: "success.main",
									fontWeight: "medium",
								}}
							>
								Skenavimas įjungtas. Skenuokite prekių barkodus
								naudodamiesi skeneriu.
							</Typography>
						</>
					))}
				{inputMode === INPUT_MODES.manual && (
					<GoodForm onSubmit={handleManualSubmit} />
				)}
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				{!handlingGoodInput &&
					(inputMode === null ? (
						<Box
							sx={{
								display: "flex",
								"& > *:not(:last-child)": { mr: 1 },
							}}
						>
							<Button
								variant="contained"
								onClick={handleManualModeClick}
							>
								Skenuoti
							</Button>
							<Button
								variant="contained"
								onClick={handleScanModeClick}
							>
								Įvesti
							</Button>
						</Box>
					) : (
						<>
							{inputMode === INPUT_MODES.scan && (
								<>
									Užrakinti kategoriją
									<Switch
										checked={checkedLockedCategory}
										onChange={(e) =>
											setCheckedLockedCategory(
												e.target.checked
											)
										}
									/>
									{checkedLockedCategory && (
										<Select
											labelId="category-label"
											label="Kategorija"
											defaultValue={CATEGORIES.other}
											sx={{ mr: 1, ml: 1 }}
											onChange={(e) =>
												setCategoryValue(e.target.value)
											}
											value={categoryValue}
										>
											{Object.values(CATEGORIES).map(
												(category) => (
													<MenuItem
														key={category}
														value={category}
													>
														{category}
													</MenuItem>
												)
											)}
										</Select>
									)}
								</>
							)}
							<Button
								variant="contained"
								onClick={handleTurnOffClick}
							>
								Išjungti
							</Button>
						</>
					))}
			</Box>
		</Box>
	);
};

GoodInput.propTypes = {
	onGoodInput: PropTypes.func.isRequired,
};

export default GoodInput;
