import { object, string, number } from 'yup';

const validationSchema = object({
  title: string().required('Pavadinimas yra privalomas'),
  weight: number()
    .typeError('Svoris privalo būti skaičius')
    .min(0.001, 'Svoris negali būti mažesnis nei 1 g')
    .required('Svoris yra privalomas'),
});

export default validationSchema;
