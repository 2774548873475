const CATEGORIES = {
	other: "Kita",
	bags: "Maiseliai",
	purses: "Rankinukai",
	special: "Speckategorija",
	itemsA: "DaiktaiA",
	itemsB: "DaiktaiB",
};

export default CATEGORIES;
