import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import validationSchema from './utils/validationSchema';

const Form = ({ onSubmit }) => {
  const formUtils = useForm({
    defaultValues: {
      identifier: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const identifierFieldBag = formUtils.register('identifier');
  const passwordFieldBag = formUtils.register('password');

  return (
    <Box sx={{ mt: 1 }}>
      <Container maxWidth="xs">
        <form onSubmit={formUtils.handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& > *:not(:last-child)': {
                mb: 1,
              },
            }}
          >
            <TextField
              label="Prisijungimo vardas"
              {...identifierFieldBag}
              inputRef={identifierFieldBag.ref}
              disabled={formUtils.formState.isSubmitting}
              error={!!formUtils.formState.errors['identifier']}
              helperText={formUtils.formState.errors['identifier']?.message}
            />
            <TextField
              label="Slaptažodis"
              type="password"
              {...passwordFieldBag}
              inputRef={passwordFieldBag.ref}
              disabled={formUtils.formState.isSubmitting}
              error={!!formUtils.formState.errors['password']}
              helperText={formUtils.formState.errors['password']?.message}
            />
            {formUtils.formState.isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" type="submit">
                Prisijungti
              </Button>
            )}
          </Box>
        </form>
      </Container>
    </Box>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
