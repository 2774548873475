import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from '@rokasaitenta/react-auth-provider';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import reportWebVitals from './reportWebVitals';

import SelectedStoreProvider from './components/SelectedStoreProvider';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        maxSnack={1}
      >
        <AuthProvider>
          <BrowserRouter>
            <SelectedStoreProvider>
              <App />
            </SelectedStoreProvider>
          </BrowserRouter>
        </AuthProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
