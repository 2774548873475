import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import formatMoney from "../../../../../../../../../../utils/currency/formatMoney";

const PriceTable = ({
	prices,
	goodsPriceCounts,
	remainderGoodsPriceCounts,
	soldGoodsPriceCounts,
}) => {
	return (
		<Paper>
			<Toolbar>
				<Typography fontWeight="medium">Atitikimo ataskaita</Typography>
			</Toolbar>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align="right">Kaina</TableCell>
							<TableCell align="right">Įkain.</TableCell>
							<TableCell align="right">Pard.</TableCell>
							<TableCell align="right">Grąž.</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{prices.map((price, index) => {
							const goodsCount = goodsPriceCounts[price] || 0;
							const remainderGoodsCount =
								remainderGoodsPriceCounts[price] || 0;
							const soldGoodsCount =
								soldGoodsPriceCounts[price] || 0;

							const discrepancy =
								goodsCount - soldGoodsCount !==
								remainderGoodsCount;

							const cellStyle = discrepancy
								? {
										color: "red",
										fontWeight: 600,
								  }
								: undefined;

							return (
								<TableRow key={`${price}-${index}`}>
									<TableCell sx={cellStyle} align="right">
										{formatMoney(price)}
									</TableCell>
									<TableCell sx={cellStyle} align="right">
										{goodsCount || "-"}
									</TableCell>
									<TableCell sx={cellStyle} align="right">
										{soldGoodsCount || "-"}
									</TableCell>
									<TableCell sx={cellStyle} align="right">
										{remainderGoodsCount || "-"}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

PriceTable.propTypes = {
	prices: PropTypes.arrayOf(PropTypes.number),
	goodsPriceCounts: PropTypes.objectOf(PropTypes.number),
	remainderGoodsPriceCounts: PropTypes.objectOf(PropTypes.number),
	soldGoodsPriceCounts: PropTypes.objectOf(PropTypes.number),
};

export default PriceTable;
