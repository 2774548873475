import { useEffect, useState } from 'react';

const useStateLocalStorage = (key, initialState) => {
  const [haveState, setHaveState] = useState(false);
  const [state, setState] = useState(initialState);

  // NOTE load stored state on initial render for key
  useEffect(() => {
    if (key) {
      const stateLocalStorage = localStorage.getItem(key);
      if (stateLocalStorage !== null) {
        setState(JSON.parse(stateLocalStorage));
      }
      setHaveState(true);

      return () => {
        setState(undefined);
        setHaveState(false);
      };
    }
  }, [key]);

  // NOTE store state on set
  useEffect(() => {
    // NOTE check if state was read for key to prevent execution before state
    // is read for the first time and overriding or clearing the local storage
    // state
    if (key && haveState) {
      if (typeof state !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(state));
      } else {
        localStorage.removeItem(key);
      }
    }
  }, [key, haveState, state]);

  return [state, setState];
};

export default useStateLocalStorage;
