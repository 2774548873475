const ENDPOINTS = {
  auth: '/auth/local',

  users: '/users',

  stores: '/stores',
  batches: '/batches',
  boxes: '/boxes',
  goods: '/goods',
  remainderGoods: '/remainder-goods',
  orders: '/orders',
  soldGoods: '/sold-goods',
};

export default ENDPOINTS;
