import _ from 'lodash';

const orderDays = (firstDay, days) => {
  const orderedDays = _.sortBy(days);

  const firstDayIndex = days.findIndex((day) => day === firstDay);

  return firstDayIndex >= 0
    ? [...days.slice(firstDayIndex), ...days.slice(0, firstDayIndex)]
    : orderedDays;
};

export default orderDays;
