import { useCallback } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { TextField } from "formik-mui";
// import { DatePicker } from "formik-mui";
import Box from "@mui/material/Box";

import getNextWeekday from "../../../../../../../../../../utils/date/getNextWeekday";

import makeValidationSchema from "./utils/makeValidationSchema";
import validateSellingStartDate from "./utils/validateSellingStartDate";

const BatchForm = ({ isSubmitting, storeFirstDay, todayDate }) => {
	const handleSellingStartDatePickerShouldDisableDate = useCallback(
		(sellingStartDate) =>
			!validateSellingStartDate(
				sellingStartDate,
				storeFirstDay.day,
				todayDate
			),
		[storeFirstDay.day, todayDate]
	);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				"& > *:not(:last-child)": {
					mb: 1,
				},
			}}
		>
			<Field
				component={TextField}
				name="title"
				label="Pavadinimas"
				fullWidth
				disabled={isSubmitting}
			/>
			{/* TODO picker locale */}
			<Field
				component={TextField}
				type="date"
				name="sellingStartDate"
				label="Prekybos pradžia"
				// minDate={getNextWeekday(storeFirstDay.day)}
				// shouldDisableDate={
				// 	handleSellingStartDatePickerShouldDisableDate
				// }
				// okText="Gerai"
				// cancelText="Atšaukti"
				fullWidth
				disabled={isSubmitting}
			/>
		</Box>
	);
};

BatchForm.propTypes = {
	isSubmitting: PropTypes.bool.isRequired,
	storeFirstDay: PropTypes.shape({
		day: PropTypes.number.isRequired,
	}).isRequired,
	todayDate: PropTypes.object.isRequired,
};

export default BatchForm;

export { makeValidationSchema };
