import * as yup from 'yup';

const validationSchema = yup.object({
	price: yup
		.number()
		.typeError("Kaina privalo būti skaičius")
		.min(0.01, "Kaina negali būti mažesnė nei 1 ct")
		.required("Kaina yra privaloma"),
	category: yup
		.string()
		.typeError("Kategorija privalo būti teksto tipo"),
});

export default validationSchema;
