import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import SignOutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { SelectedStoreContext } from '../../components/SelectedStoreProvider';
import StoreSelector from '../../components/StoreSelector';

import Batches from './features/Batches';

const WarehousePersonnelApp = () => {
  const [, , , signOut] = useAuthContext();

  const [storeId] = useContext(SelectedStoreContext);

  const handleSignOutClick = () => signOut();

  return (
    <Box>
      <AppBar position="fixed">
        <Toolbar sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }} variant="h6" noWrap component="div">
            RADA sandėlio IS
          </Typography>
          <Box sx={{ display: 'flex', '& > *:not(:last-child)': { mr: 1 } }}>
            {!!storeId && (
              <Paper sx={{ px: 1, py: 0.25 }}>
                <StoreSelector />
              </Paper>
            )}
            <Button
              color="inherit"
              startIcon={<SignOutIcon />}
              onClick={handleSignOutClick}
            >
              Atsijungti
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {storeId ? (
          <Routes>
            <Route path="batches/*" element={<Batches />} />
            <Route path="*" element={<Navigate to="/batches" />} />
          </Routes>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& > *:not(:last-child)': { mb: 1 },
            }}
          >
            <Typography>Pasirinkite parduotuvę, kurioje dirbate:</Typography>
            <StoreSelector />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WarehousePersonnelApp;
