import { useContext, useEffect } from 'react';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';

import useUser from './utils/api/data-hooks/useUser';
import USER_ROLES from './utils/api/USER_ROLES';

import CenteredCircularProgress from './components/CenteredCircularProgress';
import ErrorMessage from './components/ErrorMessage';
import SignIn from './components/SignIn';
import { SelectedStoreContext } from './components/SelectedStoreProvider';

import WarehousePersonnelApp from './features/WarehousePersonnelApp';
import CashiersApp from './features/CashiersApp';

const App = () => {
  const [authState, tokenValid] = useAuthContext();

  const [storeId, setStoreId] = useContext(SelectedStoreContext);

  const userData = useUser(tokenValid && authState.user?.id, authState.token);

  useEffect(() => {
    if (userData.data && !storeId) {
      if (userData.data.stores.length === 1) {
        setStoreId(userData.data.stores[0].id);
      }
    }
  }, [userData.data, storeId, setStoreId]);

  if (!authState.user?.id || !authState.token || !tokenValid) {
    return <SignIn />;
  }

  if (userData.error) {
    return (
      <ErrorMessage>
        Nepavyko gauti prisijungusio naudotojo duomenų. Pabandykite perkrauti
        puslapį.
      </ErrorMessage>
    );
  }

  if (!userData.data) {
    return <CenteredCircularProgress />;
  }

  return [USER_ROLES.manager, USER_ROLES.warehousePersonnel].includes(
    userData.data.role.type
  ) ? (
    <WarehousePersonnelApp />
  ) : userData.data.role.type === USER_ROLES.cashiers ? (
    <CashiersApp />
  ) : null;
};

export default App;
