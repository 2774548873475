import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import validationSchema from './utils/validationSchema';

const BoxForm = ({ formUtils }) => {
  const titleFieldBag = formUtils.register('title');
  const weightFieldBag = formUtils.register('weight');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *:not(:last-child)': {
          mb: 1,
        },
      }}
    >
      <TextField
        label="Pavadinimas"
        {...titleFieldBag}
        inputRef={titleFieldBag.ref}
        disabled={formUtils.formState.isSubmitting}
        error={!!formUtils.formState.errors['title']}
        helperText={formUtils.formState.errors['title']?.message}
        fullWidth
      />
      <TextField
        label="Svoris, kg"
        {...weightFieldBag}
        inputRef={weightFieldBag.ref}
        disabled={formUtils.formState.isSubmitting}
        error={!!formUtils.formState.errors['weight']}
        helperText={formUtils.formState.errors['weight']?.message}
        fullWidth
      />
    </Box>
  );
};

BoxForm.propTypes = {
  formUtils: PropTypes.object,
};

export default BoxForm;

export { validationSchema };
