import _ from "lodash";
import { Info } from "luxon";
import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import PriceTable from "./components/PriceTable";

const weekdays = Info.weekdays("long", {
	locale: "lt-LT",
	numberingSystem: "europe",
});

const SoldGoodsWeekView = ({
	days,
	priceCountsMapByDay,
	categoryCountsByDay,
}) => {
	return (
		<Box sx={{ "& > *:not(:last-child)": { mb: 1 } }}>
			{days.map((day, index) => {
				const priceCounts = priceCountsMapByDay[day];
				const categoryCounts = categoryCountsByDay[day];

				const sortedPrices = _.sortBy(
					_.keys(priceCounts).map((priceKey) =>
						parseFloat(priceKey, 10)
					)
				);
				const totalPrice = sortedPrices.reduce((totalPrice, price) => {
					const value = totalPrice + price * priceCounts[price].count;
					return isNaN(value) ? 0 : value;
				}, 0);

				return (
					<PriceTable
						key={`${day}-${index}`}
						weekdayTitle={_.capitalize(weekdays[day - 1])}
						prices={sortedPrices}
						categories={categoryCounts}
						priceCounts={priceCounts}
						totalPrice={totalPrice}
					/>
				);
			})}
		</Box>
	);
};

SoldGoodsWeekView.propTypes = {
	days: PropTypes.arrayOf(PropTypes.number).isRequired,
	priceCountsMapByDay: PropTypes.objectOf(
		PropTypes.objectOf(PropTypes.object)
	).isRequired,
	categoryCountsByDay: PropTypes.objectOf(
		PropTypes.objectOf(PropTypes.object)
	).isRequired,
};

export default SoldGoodsWeekView;
