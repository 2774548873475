import { DateTime } from 'luxon';
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import DEFAULT_TIMEZONE from '../../../../../../../../utils/date/DEFAULT_TIMEZONE';

import BatchForm, { makeValidationSchema } from './components/BatchForm';

const BatchDialog = ({
  title,
  data,
  storeFirstDay,
  onSubmit,
  onClose,
  ...restProps
}) => {
  const todayDate = DateTime.now().setZone(DEFAULT_TIMEZONE);

  const validationSchema = makeValidationSchema(storeFirstDay.day, todayDate);

  const handleCloseClick = () => {
    onClose();
  };

  return (
    <Dialog onClose={onClose} {...restProps}>
      <DialogTitle sx={{ wordWrap: 'break-word' }}>{title}</DialogTitle>
      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formProps) => (
          <Form>
            <DialogContent>
              <BatchForm
                {...formProps}
                storeFirstDay={storeFirstDay}
                todayDate={todayDate}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={handleCloseClick}
                disabled={formProps.isSubmitting}
              >
                Uždaryti
              </Button>
              {formProps.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" type="submit">
                  Išsaugoti
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

BatchDialog.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sellingStartDate: PropTypes.object.isRequired,
  }).isRequired,
  storeFirstDay: PropTypes.shape({
    day: PropTypes.number.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BatchDialog;
