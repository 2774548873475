import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const ErrorMessage = (props) => (
  <Typography color="error" textAlign="center" m={2} {...props} />
);

ErrorMessage.propTypes = {
  children: PropTypes.node,
};

export default ErrorMessage;
