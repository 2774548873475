import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '@rokasaitenta/react-auth-provider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ENDPOINTS from '../../../../../../../../../../utils/api/ENDPOINTS';
import axiosInstance from '../../../../../../../../../../utils/api/axiosInstance';
import useBatch from '../../../../../../../../../../utils/api/data-hooks/useBatch';

import { SelectedStoreContext } from '../../../../../../../../../../components/SelectedStoreProvider';
import PageTitle from '../../../../../../../../../../components/PageTitle';
import ErrorMessage from '../../../../../../../../../../components/ErrorMessage';
import CenteredCircularProgress from '../../../../../../../../../../components/CenteredCircularProgress';

import Boxes from './components/Boxes';
import BoxDialog from './components/BoxDialog';

const BoxList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [{ token }] = useAuthContext();

  const { batchId } = useParams();

  const [storeId] = useContext(SelectedStoreContext);

  const batchData = useBatch(batchId, token);

  const [boxDialogOpen, setBoxDialogOpen] = useState(false);

  const handleAddClick = () => {
    setBoxDialogOpen(true);
  };

  const handleBoxDialogClose = () => {
    setBoxDialogOpen(false);
  };

  const handleBoxUpdate = async (id, data) => {
    try {
      await axiosInstance.put(
        `${ENDPOINTS.boxes}/${id}`,
        {
          ...data,
          batch: parseInt(batchId, 10),
          store: storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      batchData.mutate();
      enqueueSnackbar('Maišas paredaguotas sėkmingai', { variant: 'success' });
      return true;
    } catch (error) {
      enqueueSnackbar('Nepavyko paredaguoti maišo. Pabandykite dar kartą.', {
        variant: 'error',
      });
      return false;
    }
  };

  const handleBoxCreate = async (data) => {
    try {
      await axiosInstance.post(
        ENDPOINTS.boxes,
        {
          ...data,
          batch: parseInt(batchId, 10),
          store: storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      batchData.mutate();
      enqueueSnackbar('Maišas pridėtas sėkmingai', { variant: 'success' });
      setBoxDialogOpen(false);
    } catch (error) {
      enqueueSnackbar('Nepavyko pridėti maišo. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  const handleBoxDelete = async (id) => {
    try {
      await axiosInstance.delete(`${ENDPOINTS.boxes}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      batchData.mutate();
      enqueueSnackbar('Maišas ištrintas sėkmingai', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko ištrinti maišo. Pabandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  if (batchData.error) {
    return (
      <ErrorMessage>
        Nepavyko gauti partijos maišų sąrašo. Pabandykite perkrauti puslapį.
      </ErrorMessage>
    );
  }

  if (!batchData.data) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box>
      <PageTitle>"{batchData.data.title}" įkainavimas</PageTitle>
      <Box sx={{ mb: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={handleAddClick}>
          Pradėti naują maišą
        </Button>
      </Box>
      {batchData.data.boxes.length > 0 ? (
        <Boxes
          data={batchData.data.boxes}
          totalGoods={batchData.data.goods.length}
          totalPrice={_.sumBy(batchData.data.goods, 'price')}
          onDataUpdate={handleBoxUpdate}
          onBoxDelete={handleBoxDelete}
        />
      ) : (
        <Typography>Partijoje nėra maišų.</Typography>
      )}
      <BoxDialog
        open={boxDialogOpen}
        onClose={handleBoxDialogClose}
        title="Naujas maišas"
        data={{ title: '', weight: '' }}
        onSubmit={handleBoxCreate}
      />
    </Box>
  );
};

export default BoxList;
