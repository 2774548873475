import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import formatMoney from "../../../../../../../../../../../../utils/currency/formatMoney";

const PriceTable = ({
	weekdayTitle,
	prices,
	priceCounts,
	totalPrice,
	categories,
}) => {
	return (
		<Paper>
			<Toolbar>
				<Typography fontWeight="medium">
					{weekdayTitle}: {formatMoney(totalPrice)}
				</Typography>
			</Toolbar>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align="right">Kaina</TableCell>
							<TableCell align="right">
								Parduota{" "}
								{Object.keys(priceCounts).reduce(
									(acc, price) => {
										return acc + priceCounts[price].count;
									},
									0
								)}{" "}
								vnt.
							</TableCell>
							<TableCell align="right">Suma</TableCell>
							<TableCell align="right">
								Suma po nuolaidos
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{prices.map((price, index) => {
							return (
								<TableRow key={`${price.count}${index}`}>
									<TableCell align="right">
										{formatMoney(price)}
									</TableCell>
									<TableCell align="right">
										{priceCounts[price].count || 0}
									</TableCell>
									<TableCell align="right">
										{formatMoney(
											price * priceCounts[price].count
										) || 0}
									</TableCell>
									<TableCell align="right">
										{formatMoney(
											priceCounts[price].actualPrice
										) || 0}
									</TableCell>
								</TableRow>
							);
						})}
						{categories !== undefined && (
							<>
								<TableRow>
									<TableCell align="left">
										<Typography fontWeight="medium">
											Kategorijos
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontWeight="medium">
											Kiekis
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontWeight="medium">
											Suma
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontWeight="medium">
											Suma po nuolaidos
										</Typography>
									</TableCell>
								</TableRow>
								{Object.keys(categories).map(
									(category, index) => {
										return (
											<TableRow
												key={`${category}${index}`}
											>
												<TableCell align="right">
													{category.toString() === "0"
														? "Be kategorijos"
														: category}
												</TableCell>
												<TableCell align="right">
													{categories[category]
														.count || 0}
												</TableCell>
												<TableCell align="right">
													{formatMoney(
														categories[category]
															.price || 0
													)}
												</TableCell>
												<TableCell align="right">
													{formatMoney(
														categories[category]
															.actualPrice || 0
													)}
												</TableCell>
											</TableRow>
										);
									}
								)}
							</>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

PriceTable.propTypes = {
	weekdayTitle: PropTypes.string.isRequired,
	prices: PropTypes.arrayOf(PropTypes.number),
	priceCounts: PropTypes.objectOf(PropTypes.object),
	totalPrice: PropTypes.number.isRequired,
};

export default PriceTable;
